<template>
  <div class="page-rooter-fff">
    <headBar ref="header" title="反馈详情" left-arrow @click-left="newAppBack" />
    <div class="box">
      <div class="title">
        反馈类型：{{ detail.feedbackType == 1 ? "我要投诉" : "提出建议" }}
      </div>
      <div class="question f24">
        <div class="row-between-start">
          <p class="p1 flex">
            <span class="flex-none">{{ questionType[0]}}</span>
            <img src="https://img.chaolu.com.cn/MINI/feedback/triangle.png" alt="" />
            <span>{{ questionType[1] }}</span>
          </p>
          <p :class="[detail.resolveStatus == 1 ? 'gray' : '', 'p2', 'flex-none']">{{
            detail.resolveStatus == 1 ? "已完结" : "待解决"
          }}</p>
        </div>
        <p v-if="detail.venueTitle">门店：{{ detail.venueTitle }}</p>
      </div>
      <div class="title">反馈内容</div>

      <div class="content">
        <p>{{ detail.content }}</p>
        <div v-if="detail.annexStrList && detail.annexStrList.length" class="pic-box row-start-center">
          <img
            @click="clickImage(index)"
            v-for="(item, index) in detail.annexStrList"
            :src="item"
            :key="index"
            alt=""
          />
        </div>
        <span v-if="detail.creatDate" >{{ detail.creatDate }}</span>
      </div>

      <!-- 客服是否已经回复 -->
      <template v-if="detail.resolveList.length">
        <div class="title">客服回复</div>
        <div class="content" v-for="item in detail.resolveList">
          <p>
            {{ item.record }}
          </p>
          <span>{{ item.createDate }}</span>
        </div>
      </template>
      <template v-else>
        <div class="title">待客服回复</div>
        <div class="content">
          <p>
            小鹿感谢您的反馈，相关人员正在紧急处理中，请您稍安勿躁，有具体结果将通过站内信/短信的形式通知您。
          </p>
        </div>
      </template>

      <template v-if="detail.isHidden !== 1">
        <!-- 用户未反馈 -->
        <template
          v-if="detail.resolveList.length && detail.resolveList[0].userComment === 0"
        >
          <div class="title">您对客服处理结果：</div>
          <div class="evaluate row-between-center">
            <div
              @click="selectEvaluate(1)"
              :class="{ active: evaluate === 1 }"
              class="row-center-center evaluate1"
            >
              <p></p>
              满意
            </div>
            <div
              @click="selectEvaluate(2)"
              :class="{ active: evaluate === 2 }"
              class="row-center-center evaluate2"
            >
              <p></p>
              不满意
            </div>
          </div>
        </template>
        <!-- 用户是否已经评价 -->
        <template v-else>
          <!-- 用户不满意 -->
          <p class="tips" v-if="detail.resolveList.length && detail.resolveList[0].userComment === 2">
            非常抱歉此次处理未能让您满意，请问是否愿意进一步向<span @click="jumpCustomer()">在线客服</span>部门反馈，以便我们向您跟进一步了解相关反馈内容，为您带来更好的服务体验。
          </p>
          <!-- 用户满意 -->
          <p class="tips" v-if="detail.resolveList.length && detail.resolveList[0].userComment === 1">感谢您的反馈，超鹿变得更好离不开您长期的支持！</p>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { gotoCustomerService, initBack, newAppBack } from "@/lib/appMethod";
import userMixin from "@/mixin/userMixin";
import { getParam } from "@/lib/utils";
import headBar from "@/components/app/headBar";
import wx from "weixin-js-sdk";
export default {
  components: {
    headBar,
  },
  mixins: [userMixin],
  data() {
    return {
      detail: {
        resolveList: []
      },

      evaluate: 0,

      questionTypes: [], // 问题类型
    };
  },
  computed: {
    questionType() {
      if (!this.questionTypes.length) return ['', '']
      let q = this.questionTypes.filter(
        (item) => item.id == this.detail.feedbackClassify
      );
      let str = ''
      if (q.length && this.detail.feedbackSecondClassify) {
        let arr = this.detail.feedbackSecondClassify.split(',')
        console.log(arr);
        arr.map((item) => {
          let filterArr = q[0].treeList.filter((qtem) => qtem.id == item)
          console.log(filterArr);
          if (filterArr.length) str = str ? `${str} / ${filterArr[0].classifyName}` : `${filterArr[0].classifyName}`
        })

      }
      return q.length ? [q[0].classifyName, str]  : ['', ''];
    },
  },
  async created() {
    initBack();
    await this.$getUserId();
    this.feedbackId = getParam().feedbackId;

    this.getDetail();
    this.getQuestionTypes();
  },
  methods: {
    newAppBack,
    jumpCustomer() {
      gotoCustomerService();
    },
    clickImage(index) {
      ImagePreview({
        images: this.detail.annexStrList,
        startPosition: index,
        closeable: true,
      });
    },
    selectEvaluate(v) {
      this.evaluate = v;
      this.handleFeedback();
    },
    getDetail() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });

      this.$axios
        .post(`${this.baseURLApp}/feedback/user/detail`, {
          feedbackId: this.feedbackId,
        })
        .then((res) => {
          this.detail = res.data;

          this.$toast.clear();
        })
        .catch((r) => {
          this.$toast.clear();
        });
    },
    getQuestionTypes() {
      this.$axios
        .post(`${this.baseURLApp}/feedback/user/defaultMessage`, {
          userId: this.userId,
          token: this.token,
        })
        .then((res) => {
          this.questionTypes = res.data.classifyVOList;
        });
    },

    handleFeedback() {
      this.$axios
        .post(`${this.baseURLApp}/feedback/user/comment`, {
          resolveId: this.detail.resolveList[0].id,
          userComment: this.evaluate,
          feedbackId: this.feedbackId
        })
        .then((res) => {
          this.getDetail();
        });
    },
  },
};
</script>

<style scoped lang="less">
.page-rooter-fff {
  background: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  .title {
    color: #242831;
    font-size: 28px;
    font-weight: bold;
  }
  .box {
    padding: 44px 32px 100px;
  }
  .question {
    width: 686px;
    background: #ffffff;
    border-radius: 16px;
    padding: 34px 33px;
    box-sizing: border-box;
    margin: 24px 0 40px;
    div {
      .p1 {
        color: #242831;
        padding: 0 24px 0 0;
        img {
          width: 12px;
          height: 13px;
          margin: 9px 14px 0;
        }
      }
      .p2 {
        color: #f03c18;
        font-weight: bold;
        &.gray {
          color: #242831;
        }
      }
    }
    & > p {
      margin-top: 28px;
      line-height: 24px;
      color: #242831;
      font-size: 24px;
    }
  }
  .content {
    width: 686px;
    background: #ffffff;
    border-radius: 16px;
    padding: 34px 33px;
    box-sizing: border-box;
    margin: 24px 0 40px;
    p {
      text-align: justify;
      font-size: 24px;
      color: #242831;
      line-height: 40px;
      word-break: break-all;
    }
    span {
      font-size: 24px;
      color: #6c727a;
      line-height: 24px;
      display: block;
      margin-top: 18px;
    }
    div {
      margin-top: 18px;
      flex-wrap: wrap;
      img {
        width: 140px;
        height: 140px;
        border-radius: 8px;
        object-fit: cover;
        margin-right: 18px;
        &:nth-child(4n) {
          margin-right: 0;
        }
        margin-bottom: 18px;
      }
    }
  }
  .evaluate {
    margin-top: 24px;
    div {
      width: 330px;
      height: 100px;
      background: #f5f5f5;
      border: 1px solid #dddddd;
      border-radius: 16px;
      color: #242831;
      font-size: 24px;
      p {
        width: 50px;
        height: 50px;
        margin-right: 18px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.evaluate1 {
        p {
          background-image: url("https://img.chaolu.com.cn/MINI/feedback/satisfaction_unselected.png");
        }
      }
      &.evaluate2 {
        p {
          background-image: url("https://img.chaolu.com.cn/MINI/feedback/dissatisfaction_unselected.png");
        }
      }

      &.active {
        color: #242831;
        background: #fff9f4;
        border: 2px solid #ed6c1a;
        &.evaluate1 {
          p {
            background-image: url("https://img.chaolu.com.cn/MINI/feedback/satisfaction_selected.png");
          }
        }
        &.evaluate2 {
          p {
            background-image: url("https://img.chaolu.com.cn/MINI/feedback/dissatisfaction_selected.png");
          }
        }
      }
    }
  }
  .tips {
    padding: 0 29px;
    font-size: 24px;
    color: #6c727a;
    line-height: 44px;
    span {
      background: #ffde00;
      border-radius: 6px;
      color: #242831;
      font-size: 20px;
      font-weight: bold;
      padding: 8px 14px;
      margin: 0 8px;
    }
  }
}
</style>
